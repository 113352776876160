import React, { useRef, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import distances from 'src/assets/styles/distances.js'
import bulet from 'src/assets/svg/bulet.svg'
import bg from 'src/assets/svg/wigilia-bg.svg'

import MainWrapper from 'src/components/global/MainWrapper.js'

const Wrapper = styled.div`
  /* margin-top: 80rem; */
  position: relative;
  background-color: ${colors.darkGreen};
  padding: 40rem 0rem;
`

const TopRow = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`
const Title = styled.h2`
  font-size: ${typographySizes.l}rem;
  text-transform: uppercase;
  position: relative;
  color: ${colors.white};
  line-height: 1.1;
  margin-bottom: 100rem;
  width: calc(50% - 15rem);
  margin-right: 30rem;
  ::after {
    content: '';
    height: 1rem;
    background-color: white;
    position: absolute;
    bottom: -40rem;
    left: 0;
    width: 200rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 70rem;
  }
`

const Paragraph = styled.p`
  font-size: ${typographySizes.s}rem;
  font-family: 'Poppins', sans-serif;
  color: ${colors.white};

  width: 33%;

  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 30rem;
  }
`

const MidRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`

const MainInfoCol = styled.div`
  width: calc(50% - 75rem);
  background-color: ${colors.bright};
  padding: 30rem;
  border-radius: 20rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: center 30rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 60rem);
    margin-bottom: 30rem;
    border-radius: 9rem;
  }
`

const MainInfoTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  color: ${colors.darkGreen};

  text-transform: uppercase;
  line-height: 1;
  max-width: 350rem;
  margin-bottom: 30rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50rem;
  }
`

const MainInfoColBottom = styled.div`
  padding: 30rem;
  background-color: ${colors.white};
  border-radius: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 15rem;
    border-radius: 9rem;
  }
`

const ImgWrapper = styled.div`
  width: calc(50% - 15rem);
  border-radius: 20rem;
  overflow: hidden;
  /* height: 100%; */

  div {
    height: 100%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    border-radius: 9rem;
  }
`

const Pos = styled.p`
  font-size: ${typographySizes.s}rem;
  font-family: 'Poppins', sans-serif;
  color: ${colors.dark};
  margin-left: 45rem;
  position: relative;
  margin-bottom: 30rem;
  max-width: 420rem;
  ::before {
    position: absolute;
    content: '';
    background-image: url(${bulet});
    width: 26rem;
    height: 29rem;
    background-size: contain;
    left: -46rem;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    margin-bottom: 15rem;
  }
  :last-of-type {
    margin-bottom: 0;
  }
`

const BottomRow = styled.div`
  padding: 30rem;
  width: calc(100% - 60rem);
  margin-top: 30rem;
  border-radius: 20rem;
  background-color: ${colors.bright};
  @media (max-width: ${mediaQuery.tablet}) {
    border-radius: 9rem;
  }
`

const BottomInfoParagraph = styled.p`
  font-size: ${typographySizes.s}rem;
  font-family: 'Poppins', sans-serif;
  a {
    font-weight: bold;
    font-size: inherit;
    text-decoration: none;
    color: ${colors.darkGreen};
  }
`

const CompanyEve = props => {
  return (
    <Wrapper id="wigilia-dla-twojego-zespoplu">
      <MainWrapper>
        <TopRow>
          <Title>
            Wiemy jak ciężki <br /> to był rok.
          </Title>
          <Paragraph>
            Odpowiednio zorganizowany wieczór na Wigilię dla Twojego zespołu
            pozwoli dobrze go zakończyć i z pozytywnym nastawieniem rozpocząć
            kolejny.
          </Paragraph>
        </TopRow>
        <MidRow>
          <MainInfoCol>
            <MainInfoTitle>
              Mamy warunki i chęci, aby pomóc Ci przygotować ten wyjątkowy
              wieczór
            </MainInfoTitle>
            <MainInfoColBottom>
              <Pos>przyjmujemy rezerwacje na tego typu okazje</Pos>
              <Pos>
                wynajmiemy salę na wyłączność, która zmieści grupy do 30 osób
              </Pos>
              <Pos>
                przygotujemy ofertę na kolację dostosowaną do Twoich oczekiwań
              </Pos>
              <Pos>zajmiemy się doborem wina i innych alkoholi</Pos>
              <Pos>
                zadbamy o świąteczny klimat, profesjonalną obsługę i przyjemną
                muzykę
              </Pos>
            </MainInfoColBottom>
          </MainInfoCol>
          <ImgWrapper>
            <StaticImage
              src="../../assets/images/firmowa.png"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </ImgWrapper>
        </MidRow>
        <BottomRow>
          <BottomInfoParagraph>
            Wykorzystaj tę okazję, aby jeszcze bardziej wzmocnić więzi w
            zespole.
            <br />
            Napisz do nas{' '}
            <a href="mailto:hello@kimidori.pl">hello@kimidori.pl</a> lub zadzwoń{' '}
            <a href="tel:224006633">224006633 </a>i zapytaj o indywidualną
            ofertę.
          </BottomInfoParagraph>
        </BottomRow>
      </MainWrapper>
    </Wrapper>
  )
}

export default CompanyEve
