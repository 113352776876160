import React, { useRef, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import distances from 'src/assets/styles/distances.js'
import close from 'src/assets/svg/x.svg'

const Bg = styled.div`
  background-color: ${colors.bright};

  height: 100vh;
  width: 100vw;

  opacity: 0.7;
`

const PopUpWraper = styled.div`
  width: 40%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20rem;
  background-color: ${colors.white};
  border-radius: 23rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 80%;
    padding: 10rem;
    border-radius: 10rem;
  }
`

const ImgWrapper = styled.div`
  border-radius: 23rem;
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    border-radius: 10rem;
  }
`

const Close = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
`
const animation = keyframes`
    0% {
     opacity:0
    }
    to {
     opacity:100%
    }
   `
const Wraper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  animation-name: ${animation};
  animation-duration: 0.8s;
  &.open {
    display: block;
  }
`

const PopUpImg = props => {
  const [open, setOpen] = useState(true)
  const clickFn = () => {
    setOpen(false)
    console.log('test')
  }
  return (
    <Wraper className={open && 'open'}>
      <Bg></Bg>
      <PopUpWraper>
        <ImgWrapper>
          <StaticImage
            src="../../assets/images/KIMIPopUp.jpg"
            placeholder="none"
            layout="fullWidth"
            quality="100"
          />
        </ImgWrapper>
        <Close src={close} onClick={clickFn} />
      </PopUpWraper>
    </Wraper>
  )
}

export default PopUpImg
