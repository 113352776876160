import React from 'react'
import styled from 'styled-components'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import { StaticImage } from 'gatsby-plugin-image'

import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiper } from 'swiper/react'
import 'swiper/css'
import hex from 'src/assets/svg/about-hexagon-bg.svg'
import bg from '/src/assets/svg/testimonial_bg.svg'
import bigBg from '/src/assets/svg/testimonial_bg_big.svg'
import arrow from '/src/assets/svg/testimonial_arrow.svg'

import MainWrapper from 'src/components/global/MainWrapper.js'
const StyledWrapper = styled(MainWrapper)`
  margin: 80rem 0;
  position: relative;
  background-color: ${colors.darkGreen};
  overflow: hidden;
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -80rem;
    background-image: url(${bigBg});
    background-position: right bottom;
    height: 80vh;
    width: 50vw;
    background-repeat: no-repeat;
  }
`

const Wrapper = styled.section`
  padding: 40rem 0rem;
`
const Title = styled.h2`
  font-size: ${typographySizes.m}rem;
  text-transform: uppercase;
  position: relative;
  color: ${colors.white};
  line-height: 1.1;
  ::after {
    content: '';
    height: 1rem;
    background-color: white;
    position: absolute;
    bottom: -40rem;
    left: 0;
    width: 200rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 180rem;
  }
`

const TestimonialsWrapper = styled.div`
  margin-top: 100rem;
  width: 100%;
  .swiper-slide {
    height: 43vw;
    overflow: hidden;
    position: relative;

    background-image: url(${bg});

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: ${mediaQuery.tablet}) {
      height: 100vw;
    }
  }
  .swiper {
    overflow: visible;
    .gatsby-image-wrapper {
      position: static;
    }
    img {
      width: 63%;
      object-fit: contain !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: ${mediaQuery.tablet}) {
        width: 58%;
      }
    }
  }
`

const ControlWrapper = styled.div`
  display: flex;
  /* background-color: red; */

  position: absolute;
  transform: translateY(-100%);
  right: 0;
  /* margin-left: 30rem; */
  top: -60rem;
  z-index: 100;
  @media (max-width: ${mediaQuery.tablet}) {
    top: -40rem;
    right: 50%;
    transform: translate(50%, -100%);
  }
`

const RightArrow = styled.div`
  width: 50rem;
  cursor: pointer;
  background-color: white;
  height: 50rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  border-radius: 10rem;
  background-image: url(${arrow});
  background-position: center;
  background-repeat: no-repeat;
  :hover {
    transform: translateY(-5rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const LeftArrow = styled.div`
  width: 50rem;
  height: 50rem;
  margin-right: 20rem;
  background-color: white;
  border-radius: 10rem;
  background-image: url(${arrow});
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  :hover {
    transform: translateY(-5rem) rotate(180deg);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`
const Controls = () => {
  const swiper = useSwiper()

  return (
    <ControlWrapper>
      <LeftArrow onClick={() => swiper.slidePrev()} />
      <RightArrow onClick={() => swiper.slideNext()} />
    </ControlWrapper>
  )
}

const Testimonials = props => (
  <StyledWrapper>
    <Wrapper>
      <Title>
        Dziękujemy, <br /> że jesteś green
      </Title>
      <TestimonialsWrapper>
        {' '}
        <Swiper
          spaceBetween={30}
          breakpoints={{
            // when window width is >= 640px
            0: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2.5,
            },
          }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={swiper => console.log(swiper)}
          grabCursor={true}
        >
          <Controls />
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia1.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia2.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia3.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia4.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia5.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia6.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia7.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/opinie/opinia8.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
        </Swiper>
      </TestimonialsWrapper>
    </Wrapper>
  </StyledWrapper>
)

export default Testimonials
